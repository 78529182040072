import React, { Component } from 'react';
import { Typography, Divider, Grid, CardContent } from '@material-ui/core';
import { GetData } from '../../api/service';
import { Card } from 'react-bootstrap';


class DepartmentHome extends Component {
    state = {
        departments: [],
        isLoaded: false
    }

    componentDidMount() {
        GetData(`/admin/getdepartments`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    departments: resp,
                    isLoaded: true
                })
            })
    }

    render() {
        return (
            <div>
                <Typography
                    variant="h6"
                >
                    Departments
                </Typography>
                <Typography
                    variant="caption"
                >
                    Choose the department card you want to edit. If you want to add new department, please contact the administrator
                </Typography>

                <Divider />
                <br />

                {this.state.isLoaded ? (<Grid container spacing={2}>
                    {this.state.departments.map((el, index) => <Grid key={index} item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => this.props.history.replace(`/departments/${el.dept_code}/home`)}
                    >
                        <Card border="primary">
                            <CardContent>
                                <Typography
                                    variant="subtitle2"
                                >
                                    {el.dept_name} / {el.dept_name_as}
                                </Typography>
                                <Typography
                                    variant="caption"
                                >
                                    {el.dept_code}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>)}
                </Grid>) : <Typography variant="caption">Loading . . . </Typography>}

            </div>
        );
    }
}



export default DepartmentHome;