import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { Tabs, Tab } from 'react-bootstrap';
import DepartmentVision from './DepartmentVision';
import { GetData } from '../../api/service';
import DepartmentGlance from './DepartmentGlance';
import DepartmentContact from './DepartmentContact';
import DepartmentOthers from './DepartmentOthers';
import DepartmentCourses from './DepartmentCourses';
import DepartmentSyllabus from './DepartmentSyllabus';
import DepartmentFaculty from './DepartmentFaculty';
import DepartmentEvent from './DepartmentEvent';
import DepartmentAchievements from './DepartmentAchievements';
import DepartmentAlumni from './DepartmentAlumni';
import DepartmentHome from './DepartmentHome';
import DepartmentHomePage from './DepartmentHomePage';
import DepartmentActivity from './DepartmentActivity';
import DepartmentOnlineClasses from './DepartmentOnlineClasses';

const smallTab = [
    {
        title: 'Home',
        active: 0
    },
    {
        title: 'Vision',
        active: 1
    },
    {
        title: 'Glance',
        active: 2
    },
    {
        title: 'Courses',
        active: 3
    },
    {
        title: 'Syllabus',
        active: 4
    },
    {
        title: 'Journal',
        active: 5
    },
    {
        title: 'Events',
        active: 6
    },
    {
        title: 'Faculty/Employee',
        active: 7
    },
    {
        title: 'Alumni',
        active: 8
    },
    {
        title: 'Achievements',
        active: 9
    },
    {
        title: 'Others',
        active: 10
    },
    {
        title: 'Contact',
        active: 11
    },
    {
        title: 'Activity',
        active: 12
    },
    {
        title: 'Online Classes',
        active: 13
    },
    {
        title: 'COs & POs',
        active: 14
    }
]

const officeTab = [
    {
        title: 'Home',
        active: 0
    },
    
    {
        title: 'Employee',
        active: 7
    },
    
]


class DepartmentHomeLanding extends Component {
    state = {
        active: 0,
        dept_code: '',
        data: {},
        isDeptLoaded: false
    }

    __getDeptData = (dept_code) => {
        GetData(`/admin/${dept_code}/getsingledeptdata`)
            .then((resp) => {
                //console.log(resp)
                if (resp != false) {
                    this.setState({
                        data: resp,
                        active: dept_code =='OFF'?7:0,
                        isDeptLoaded: true
                    })
                } else {
                    this.setState({
                        data: {},
                        active: dept_code =='OFF'?7:0,

                        isDeptLoaded: true
                    })
                }

            })
    }

    componentDidMount() {
        let dept_code = this.props.match.params.dept_code;
        this.setState({
            dept_code: dept_code
        }, () => {
            this.__getDeptData(this.state.dept_code)
        })
    }

    render() {
        return (
            <div>
                <Typography
                    variant="h6"
                >
                    {this.state.data.dept_name} / {this.state.data.dept_name_as}
                </Typography>
                <Typography
                    variant="subtitle2"
                >
                    Code: {this.state.dept_code}
                </Typography>


                <br />

                {this.state.isDeptLoaded && (this.state.dept_code !='OFF' && this.state.dept_code !='LIB' && this.state.dept_code !='BEARER') ? (<Tabs activeKey={this.state.active} onSelect={k => this.setState({ active: k })}>

                    {smallTab.map((el, index) =>
                        <Tab key={index} eventKey={index} title={el.title}>
                            <br />




                        </Tab>

                    )}
                </Tabs>) : null}

                {this.state.isDeptLoaded && (this.state.dept_code =='OFF' || this.state.dept_code =='LIB' || this.state.dept_code =='BEARER') ? (<Tabs activeKey={this.state.active} onSelect={k => this.setState({ active: k })}>

                    {officeTab.map((el, index) =>
                        <Tab key={index} eventKey={el.active} title={el.title}>
                            <br />




                        </Tab>

                    )}
                </Tabs>) : null}

                {this.state.active == 0 ? (<DepartmentHomePage
                    {...this.state.data}
                />) : null}

                {this.state.active == 1 ? (<DepartmentVision
                    {...this.state.data}
                />) : null}

                {this.state.active == 2 ? (<DepartmentGlance
                    {...this.state.data}
                />) : null}

                {this.state.active == 3 ? (<DepartmentCourses
                    {...this.state.data}
                />) : null}

                {this.state.active == 11 ? (<DepartmentContact
                    {...this.state.data}
                />) : null}

                {this.state.active == 10 ? (<DepartmentOthers
                    {...this.state.data}
                />) : null}

                {this.state.active == 4 ? (<DepartmentSyllabus
                    {...this.state.data}
                    type="SYLLABUS"

                />) : null}

                {this.state.active == 5 ? (<DepartmentSyllabus
                    {...this.state.data}
                    type="JOURNAL"

                />) : null}

                {this.state.active == 9 ? (<DepartmentAchievements
                    {...this.state.data}
                    type="ACHIEVEMENTS"

                />) : null}

                {this.state.active == 7 ? (<DepartmentFaculty
                    {...this.state.data}
                />) : null}

                {this.state.active == 6 ? (<DepartmentEvent
                    {...this.state.data}
                />) : null}

                {this.state.active == 8 ? (<DepartmentAlumni
                    {...this.state.data}
                />) : null}

                {this.state.active == 12 ? (<DepartmentActivity
                    {...this.state.data}
                />) : null}

                {this.state.active == 13 ? (<DepartmentOnlineClasses
                    {...this.state.data}
                />) : null}


{this.state.active == 14 ? (<DepartmentSyllabus
                    {...this.state.data}
                    type="CO_PO"

                />) : null}
            </div>
        );
    }
}


export default DepartmentHomeLanding;