import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, TextField, CardActions, Button } from '@material-ui/core';
import Build from '@material-ui/icons/Build'
import { PostData } from '../../../api/service';
import Snack from '../../Snackbar/Snack';

const styles = theme => ({
    buttonCard: {
        float: 'right'
    }
})

class PasswordChange extends Component {
    state = {
        email: [],
        start_id: '',
        end_id: '',
        errors: [],
        open: false,
        message: '',
        isLoading: true
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.state.confirm_password === 'Corexx@123') {
            const data = {
                start_id: this.state.start_id,
                end_id: this.state.end_id
            }

            PostData(`/${this.props.apikey}/getemailsbyid`, data)
                .then((resp) => {
                    console.log(resp);
                    let r = resp.map((el,index) => 
                        {
                            return(
                                {
                                    email: el.email,
                                    status: 'Not Sent'
                                }
                            )
                        }
                    )
                    this.setState({
                        email: r,
                        isLoading: false
                    })
                })

        } else {
            let errors = this.state.errors;
            errors['confirm_password'] = "Wrong Password";
            this.setState({
                errors
            })
        }


    }

    onFinalSubmit = (e) => {
        e.preventDefault();

        let emails = this.state.email;


        if(Array.isArray(emails) && emails.length > 0){
            emails.map((el,index) => {
                let data = {
                    email: el.email
                }
                PostData(`/${this.props.apikey}/forcechagepassword`, data)
                    .then((resp) => {
                        
                        emails[index]['status'] = resp;
                        this.setState({
                            emails
                        })

                    })
            })
        }

    }



    render() {
        let { classes } = this.props;
        let i = [];
        if (!this.state.isLoading) {
            let emails = this.state.email;
            i = emails.map((el, index) =>

            {
                
                return(
                    <tr>
                        <td>{index + 1}</td>
                        <td>{el.email}</td>
                        <td>{el.status}</td>
                    </tr>
                )
            }
                
            )
        }
        return (
            <div>

                {this.state.open ? <Snack
                    open={this.state.open}
                    message={this.state.message}
                    handleClose={() => {
                        this.setState({
                            open: false,
                            message: ''
                        })
                    }}
                /> : null}


                <form onSubmit={this.onSubmit}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <Build />
                                </Avatar>
                            }
                            title={`Force Password Change (Only for developers)`}
                        />

                        <CardContent>
                            <TextField
                                variant="outlined"
                                label="Starting ID"
                                name="start_id"
                                className={classes.textField}
                                type="number"
                                margin="normal"
                                required
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.start_id}
                                onChange={this.onChange}
                                helperText={this.state.errors['start_id']}
                                FormHelperTextProps={{
                                    error: true
                                }}
                            />

                            <TextField
                                variant="outlined"
                                label="Ending ID"
                                name="end_id"
                                className={classes.textField}
                                type="number"
                                margin="normal"
                                required
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.end_id}
                                onChange={this.onChange}
                                helperText={this.state.errors['end_id']}
                                FormHelperTextProps={{
                                    error: true
                                }}
                            />

                            <TextField
                                variant="outlined"
                                label="Enter password to proceed"
                                name="confirm_password"
                                className={classes.textField}
                                type="password"
                                margin="normal"
                                required
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.confirm_password}
                                onChange={this.onChange}
                                helperText={this.state.errors['confirm_password']}
                                FormHelperTextProps={{
                                    error: true
                                }}
                            />
                        </CardContent>
                        <CardActions className={classes.buttonCard}>
                            <Button
                                variant='outlined'
                                color="primary"
                                type="submit"
                            >
                                Search Emails
                        </Button>
                        </CardActions>
                    </Card>
                </form>


                {!this.state.isLoading ?

                    <Card>
                        <CardContent>
                            <table width="100%">

                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {i}
                                </tbody>

                            </table>
                        </CardContent>

                        <CardActions>
                            <Button
                                type="button"
                                variant="outlined"
                                onClick={this.onFinalSubmit}
                            >
                                Final Submit
                            </Button>
                        </CardActions>
                    </Card>
                    : null}
            </div>
        )
    }
}

export default withStyles(styles)(PasswordChange)