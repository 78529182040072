import React, { Component } from 'react'
import { Switch, Router, Route } from 'react-router-dom'
import Course from './Course/Course';
import Department from './Department/Department';
import Subject from './Subject/Subject';
import Stream from './Stream/Stream';
import Session from './Session/Session';
import Leave from './Leave/Leave';
import Class from './Class/Class';
import Routine from './Routine/Routine';
import Access from './Access/Access';
import PasswordChange from './PasswordChange/PasswordChange';
import Section from './Section/Section';
import DocType from './DocType/DocType';

export default class MasterLanding extends Component {
  constructor(props) {
    super(props);

    let ap = JSON.parse(localStorage.getItem('apikey'));

    this.state = {
      apikey: ap
    }

  }


  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/master/course" render={() => <Course {...this.props} apikey={this.state.apikey} />} />
          <Route exact path="/master/department" render={() => <Department {...this.props} apikey={this.state.apikey} />} />
          <Route exact path="/master/subject" render={() => <Subject {...this.props} apikey={this.state.apikey} />} />
          <Route exact path="/master/stream" render={() => <Stream {...this.props} apikey={this.state.apikey} />} />
          <Route exact path="/master/session" render={() => <Session {...this.props} apikey={this.state.apikey} />} />
          <Route exact path="/master/leave" render={() => <Leave {...this.props} apikey={this.state.apikey} />} />
          <Route exact path="/master/class" render={() => <Class {...this.props} apikey={this.state.apikey} />} />
          <Route exact path="/master/routine" render={() => <Routine {...this.props} apikey={this.state.apikey} />} />
          <Route exact path="/master/section" render={() => <Section {...this.props} apikey={this.state.apikey} />} />
          <Route exact path="/master/access" render={() => <Access {...this.props} apikey={this.state.apikey} />} />
          <Route exact path="/master/doctype" render={() => <DocType {...this.props} apikey={this.state.apikey} />} />
          <Route exact path="/master/passwordchange" render={() => <PasswordChange {...this.props} apikey={this.state.apikey} />} />
        </Switch>
      </div>
    )
  }
}
