import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';



export default class CourseTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el){
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }



    render() {
        const columns = [
            {
                name: "Session",
                options: {
                    filter: true,
                    sort: true,
                }
            },

            {
                name: "Day",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Course",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Stream",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Subject Code",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Semester",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Teacher",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Room Number",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Period",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Start Time",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "End Time",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            customToolbar: () => {
                return (
                    <IconButton><Add onClick={this.props.onAddClick} /></IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if(table_data.length > 0){
            data = table_data.map((el,index)=>
                [el.session,el.day,el.course,el.stream,el.sub_code,el.semester,el.teacher_name,el.room_number,el.period_number,el.start_time,el.end_time,<Launch onClick={this.onActionClick.bind(this,el)} />]
            )
        }else{
            data= [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Routine"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
