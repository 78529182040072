import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { GetData, PostData } from '../../api/service';
import Snack from '../Snackbar/Snack';


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class FacultyOthers extends Component {
    state = {
        value: '',
        m_type: this.props.m_type,
        employee_id: this.props.employee_id,
        email:this.props.email,        
        message: '',
        open: false,
        isLoaded: false
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    componentDidMount() {
        //console.log(this.props);
    
        GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`)
        .then((resp)=>{
            if(resp){
                this.setState({
                    ...resp,
                    value:resp.others,
                    
                  });
            }
            this.setState({
                isLoaded: true
              })
        })
    
        
      }


    onSubmit = (e) => {
        e.preventDefault();
    
        let d = this.state;
    
        PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
          (resp) => {
            //console.log(resp)
            this.setState({
              message: resp,
              open: true,
            });
          }
        );
      };


    render() {
        return (
            <div>

                {this.state.open ? (
                    <Snack
                        open={this.state.open}
                        message={this.state.message}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                    />
                ) : null}

                <form onSubmit={this.onSubmit}>
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        {this.props.title}
                    </Typography>

                    


{this.state.isLoaded && <CKEditor
                    editor={ ClassicEditor }
                    data={this.state.value}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                       // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.handleChange(`value`, data)
                        //console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />}

                    <br />

                    <div align="right">
                        <Button
                            size="sm"
                            variant="info"
                            type="submit"
                        >
                            Submit
                    </Button>
                    </div>

                </form>
            </div>
        );
    }
}


export default FacultyOthers;