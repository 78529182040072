import React, { Component } from 'react'
import ViewAllTable from './ViewAllTable';
import { GetData } from '../../api/service';

export default class ViewAllNotices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            table_data: [],
            isLoading: true
        }
        
    }

    componentDidMount() {
        GetData(`/${this.props.apikey}/getnotices`)
        .then((resp) => {
            console.log(resp);
            this.setState({
                table_data: resp,
                isLoading: false
            })
        })
    }

    actionClick = (el) => {
        console.log(el);
        this.props.history.push(`/edit`, el);
    }
    
    
  render() {
    return (
      <div>
        <ViewAllTable
            table_data={this.state.table_data}
            isLoading={this.state.isLoading}
                actionClick={this.actionClick}
        />
      </div>
    )
  }
}
