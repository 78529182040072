import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { Tabs, Tab } from 'react-bootstrap';
import { GetData } from '../../api/service';

import FacultyOthers from './FacultyOthers';
import FacultyHomePage from './FacultyHomePage';
import FacultyBasic from './FacultyBasic';
import FacultySeminar from './FacultySeminar';
import FacultyResearch from './FacultyResearch';
import FacultyPublications from './FacultyPublications';
import FacultyPhd from './FacultyPhd';
import FacultyWorkshop from './FacultyWorkshop';
import FacultyInvolvement from './FacultyInvolvement';
import FacultyAchievements from './FacultyAchievements';
import FacultyAward from './FacultyAward';

const smallTab = [
    {
        title: 'Home',
        active: 0,
        access:['FACULTY','PRINCIPAL','VICE PRINCIPAL','OFFICE','LIB','BEARER']
    },
    {
        title: 'Basic',
        active: 1,
        access:['FACULTY','PRINCIPAL','VICE PRINCIPAL','OFFICE','LIB','BEARER']
    },

    {
        title: 'Seminar',
        active: 2,
        access:['FACULTY','PRINCIPAL','VICE PRINCIPAL', 'LIB']

    },
    {
        title: 'Research',
        active: 3,
        access:['FACULTY','PRINCIPAL','VICE PRINCIPAL', 'LIB']

    },
    {
        title: 'Publications',
        active: 4,
        access:['FACULTY','PRINCIPAL','VICE PRINCIPAL','OFFICE','LIB']

    },
    
    
    {
        title: 'Training & Workshop',
        active: 5,
        access:['FACULTY','PRINCIPAL','VICE PRINCIPAL','OFFICE','LIB','BEARER']

    },
    {
        title: 'PhD and M.Phil',
        active: 6,
        access:['FACULTY','PRINCIPAL','VICE PRINCIPAL', 'LIB']

    },
    {
        title: 'Involvement',
        active: 7,
        access:['FACULTY','PRINCIPAL','VICE PRINCIPAL','OFFICE','LIB']

    },
    {
        title: 'Co-curricular ',
        active: 8,
        access:['FACULTY','PRINCIPAL','VICE PRINCIPAL','OFFICE','LIB']

    },
    {
        title: 'Awards',
        active: 9,
        access:['FACULTY','PRINCIPAL','VICE PRINCIPAL','OFFICE','LIB']

    },
    {
        title: 'Others',
        active: 10,
        access:['FACULTY','PRINCIPAL','VICE PRINCIPAL','OFFICE','LIB']

    }
]


class FacultyHomeLanding extends Component {
    state = {
        active: 0,
        employee_id: '',
        data: {},
        isDeptLoaded: false
    }

    __getDeptData = (employee_id) => {
        GetData(`/admin/${employee_id}/getsingleempolyeedata`)
            .then((resp) => {
                
                if (resp != false) {
                    this.setState({
                        data: resp,
                        active: 0,
                        isDeptLoaded: true
                    })
                } else {
                    this.setState({
                        data: {},
                        active: 0,
                        isDeptLoaded: true
                    })
                }

            })
    }

    componentDidMount() {
        let employee_id = this.props.match.params.employee_id;
        this.setState({
            employee_id: employee_id
        }, () => {
            this.__getDeptData(this.state.employee_id)
        })
    }

    render() {
        return (
            <div>
                <Typography
                    variant="h6"
                >
                    {this.state.data.name} / {this.state.data.designation}
                </Typography>
                <Typography
                    variant="subtitle2"
                >
                    Department: {this.state.data.dept_code}
                </Typography>


                <br />

                {this.state.isDeptLoaded ? (<Tabs activeKey={this.state.active} onSelect={k => this.setState({ active: k })}>

                    {smallTab.map((el, index) =>
                    {
                        if(el.access.includes(this.state.data.type))
                    
                       return <Tab key={index} eventKey={index} title={el.title}>
                            <br />




                        </Tab>}

                    )}
                </Tabs>) : null}

{this.state.isDeptLoaded && <React.Fragment>
    
    
   

                {this.state.active == 0 ? (<FacultyHomePage
                    {...this.state.data}
                    type="liveNow"
                    title="Faculty Home Page"
                    value={this.state.data.liveNow}
                    employee_id={this.state.employee_id}
                />) : null}


                {this.state.active == 1 ? (<FacultyBasic
                    {...this.state.data}
                    m_type="basic"
                    title="Basic Details"
                    value={this.state.data.basic}
                    employee_id={this.state.employee_id}
                />) : null}



{this.state.active == 2 ? (<FacultySeminar
                    {...this.state.data}
                    m_type="seminar"
                    title="Seminar Participated"
                    value={this.state.data.seminar}
                    employee_id={this.state.employee_id}
                />) : null}


{this.state.active == 3 ? (<FacultyResearch
                    {...this.state.data}
                    m_type="research"
                    title="Research Projects"
                    value={this.state.data.research}
                    employee_id={this.state.employee_id}
                />) : null}


{this.state.active == 4 ? (<FacultyPublications
                    {...this.state.data}
                    m_type="publications"
                    title="Publications"
                    value={this.state.data.publications}
                    employee_id={this.state.employee_id}
                />) : null}


{this.state.active == 5 ? (<FacultyWorkshop
                    {...this.state.data}
                    m_type="workshop"
                    title="Workshops attended"
                    value={this.state.data.workshop}
                    employee_id={this.state.employee_id}
                />) : null}

{this.state.active == 6 ? (<FacultyPhd
                    {...this.state.data}
                    m_type="phd"
                    title="PhD and M.Phill"
                    value={this.state.data.phd}
                    employee_id={this.state.employee_id}
                />) : null}


{this.state.active == 7 ? (<FacultyInvolvement
                    {...this.state.data}
                    m_type="involvement"
                    title="Involvement in College and Outside"
                    value={this.state.data.involvement}
                    employee_id={this.state.employee_id}
                />) : null}

{this.state.active == 8 ? (<FacultyAchievements
                    {...this.state.data}
                    m_type="cocurricular"
                    title="Co-curricular Achievements"
                    value={this.state.data.cocurricular}
                    employee_id={this.state.employee_id}
                />) : null}


{this.state.active == 9 ? (<FacultyAward
                    {...this.state.data}
                    m_type="awards"
                    title="Awards Received"
                    value={this.state.data.awards}
                    employee_id={this.state.employee_id}
                />) : null}

                

                {this.state.active == 10 ? (<FacultyOthers
                    {...this.state.data}
                    m_type="others"
                    title="Others"
                    value={this.state.data.others}
                    employee_id={this.state.employee_id}
                />) : null}



</React.Fragment>}
            </div>
        );
    }
}


export default FacultyHomeLanding;