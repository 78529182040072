import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    }
})

class CourseAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            session: '',
            course: '',
            stream: '',
            semester: '',
            day: '',
            sub_code: '',
            teacher: '',
            id: '',
            term_name: 'TERM',
            term_list: [1, 2, 3, 4, 5, 6],
            room_number: '',
            period_number: '',
            start_time: '',
            end_time: '',
            section: ''
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'course') {
            let term_name = ""; let term_list = [];
            let course = this.props.courses;
            let results = course.filter((el) => el.name === e.target.value);
            if (results.length > 0) {
                term_name = results[0].tenure;
                let no_of_term = parseFloat(results[0].no_of_term);
                for (let i = 1; i <= no_of_term; i++) {
                    term_list.push(i);
                }
                this.setState({
                    term_name,
                    term_list
                })
            }
        }
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.setState({
                session: '',
                course: '',
                stream: '',
                semester: '',
                day: '',
                sub_code: '',
                teacher: '',
                id: '',
                term_name: 'TERM',
                term_list: [1, 2, 3, 4, 5, 6],
                room_number: '',
                period_number: '',
                start_time: '',
                end_time: '',
                section: ''
            })
        }




    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.setState({
                    session: '',
                    course: '',
                    stream: '',
                    semester: '',
                    day: '',
                    sub_code: '',
                    teacher: '',
                    id: '',
                    term_name: 'TERM',
                    term_list: [1, 2, 3, 4, 5, 6],
                    room_number: '',
                    period_number: '',
                    start_time: '',
                    end_time: '',
                    section: ''
                })
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;
       

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            
        }
        
        this.setState({
            session: '',
            course: '',
            stream: '',
            semester: '',
            day: '',
            sub_code: '',
            teacher: '',
            id: '',
            term_name: 'TERM',
            term_list: [1, 2, 3, 4, 5, 6],
            room_number: '',
            period_number: '',
            start_time: '',
            end_time: '',
            section: ''
        })

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        let stream = [];
        let course = [];
        let session = [];
        let subject = [];
        let teacher = [];
        let semester = [];
        if (this.props.is_all_loaded) {
            let i = this.props.courses;
            if (Array.isArray(i) && i.length > 0) {
                course = i.map((el, index) =>
                    <MenuItem key={index} value={el.name}>{el.name}</MenuItem>
                )
            }

            let j = this.props.streams;
            if (Array.isArray(j) && j.length > 0) {
                stream = j.map((el, index) =>
                    <MenuItem key={index} value={el.stream_name}>{el.stream_name}</MenuItem>
                )
            }

            let k = this.state.term_list;
            if (Array.isArray(k) && k.length > 0) {
                semester = k.map((el, index) =>
                    <MenuItem key={index} value={el}>{el}</MenuItem>
                )
            }

            let l = this.props.sessions;
            if (Array.isArray(l) && l.length > 0) {
                session = l.map((el, index) =>
                    <MenuItem key={index} value={el.session_name}>{el.session_name}</MenuItem>
                )
            }

            let m = this.props.subjects;
            if (Array.isArray(m) && m.length > 0) {
                subject = m.map((el, index) =>
                    <MenuItem key={index} value={el.sub_code}>{el.sub_name}</MenuItem>
                )
            }

            let n = this.props.teachers;

            if (Array.isArray(n) && n.length > 0) {
                teacher = n.map((el, index) =>
                    <MenuItem key={index} value={el.data.email}>{el.data.name} - {el.data.dept_code}</MenuItem>
                )
            }
        }
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Session</InputLabel>
                                <Select
                                    value={this.state.session}
                                    onChange={this.onChange}
                                    name="session"
                                    inputProps={{
                                        name: 'session'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {session}
                                </Select>
                            </FormControl>

                            <br />
                            <br />


                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Course</InputLabel>
                                <Select
                                    value={this.state.course}
                                    onChange={this.onChange}
                                    name="course"
                                    inputProps={{
                                        name: 'course'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {course}
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Stream</InputLabel>
                                <Select
                                    value={this.state.stream}
                                    onChange={this.onChange}
                                    name="stream"
                                    inputProps={{
                                        name: 'stream'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {stream}
                                </Select>
                            </FormControl>

                            <br />
                            <br />


                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select {this.state.term_name}</InputLabel>
                                <Select
                                    value={this.state.semester}
                                    onChange={this.onChange}
                                    name="semester"
                                    inputProps={{
                                        name: 'semester'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {semester}
                                </Select>
                            </FormControl>


                            <br />
                            <br />


                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Day</InputLabel>
                                <Select
                                    value={this.state.day}
                                    onChange={this.onChange}
                                    name="day"
                                    inputProps={{
                                        name: 'day'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="Mon">Monday</MenuItem>
                                    <MenuItem value="Tue">Tuesday</MenuItem>
                                    <MenuItem value="Wed">Wednesday</MenuItem>
                                    <MenuItem value="Thu">Thursday</MenuItem>
                                    <MenuItem value="Fri">Friday</MenuItem>
                                    <MenuItem value="Sat">Saturday</MenuItem>
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Subject Code</InputLabel>
                                <Select
                                    value={this.state.sub_code}
                                    onChange={this.onChange}
                                    name="sub_code"
                                    inputProps={{
                                        name: 'sub_code'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {subject}
                                </Select>
                            </FormControl>


                            <br />
                            <br />

                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Teacher</InputLabel>
                                <Select
                                    value={this.state.teacher}
                                    onChange={this.onChange}
                                    name="teacher"
                                    inputProps={{
                                        name: 'teacher'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {teacher}
                                </Select>
                            </FormControl>


                            <br />
                            <br />

                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Section (if applicable)</InputLabel>
                                <Select
                                    value={this.state.section}
                                    onChange={this.onChange}
                                    name="section"
                                    inputProps={{
                                        name: 'section'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="A">A</MenuItem>
                                    <MenuItem value="B">B</MenuItem>
                                    <MenuItem value="C">C</MenuItem>
                                    <MenuItem value="D">D</MenuItem>
                                </Select>
                            </FormControl>

                            <br/>


                            <TextField
                                label="Room Number"
                                name="room_number"
                                className={classes.textField}
                                type="text"
                                margin="normal"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textStyle
                                    }
                                }}
                                value={this.state.room_number}
                                onChange={this.onChange}
                            />

                            <br />


                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Period</InputLabel>
                                <Select
                                    value={this.state.period_number}
                                    onChange={this.onChange}
                                    name="period_number"
                                    inputProps={{
                                        name: 'period_number'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={1}>1st Period</MenuItem>
                                    <MenuItem value={2}>2nd Period</MenuItem>
                                    <MenuItem value={3}>3rd Period</MenuItem>
                                    <MenuItem value={4}>4th Period</MenuItem>
                                    <MenuItem value={5}>5th Period</MenuItem>
                                    <MenuItem value={6}>6th Period</MenuItem>
                                    <MenuItem value={7}>7th Period</MenuItem>
                                    <MenuItem value={8}>8th Period</MenuItem>
                                    <MenuItem value={9}>9th Period</MenuItem>
                                    <MenuItem value={10}>10th Period</MenuItem>
                                    <MenuItem value={11}>11th Period</MenuItem>
                                    <MenuItem value={12}>12th Period</MenuItem>
                                </Select>
                            </FormControl>

                            <br />


                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>

                                    <TextField
                                        label="Start Time"
                                        name="start_time"
                                        className={classes.textField}
                                        type="time"
                                        margin="normal"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            shrink: true
                                        }}
                                        value={this.state.start_time}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} lg={6}>

                                    <TextField
                                        label="End Time"
                                        name="end_time"
                                        className={classes.textField}
                                        type="time"
                                        margin="normal"
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle,
                                            
                                            },
                                            shrink: true
                                        }}
                                        value={this.state.end_time}
                                        onChange={this.onChange}
                                    />

                                </Grid>
                            </Grid>
                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(CourseAdd)