import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';



export default class ViewAllTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el){
        this.props.actionClick(el);
    }

    getText(value){
        if(value === 1){
            return "YES";
        }else{
            return "NO";
        }
    }




    render() {
        const columns = [
            {
                name: "#",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Type",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Posted As",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Opening date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Closing Date",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Title",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Description",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Time_stamp",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        
        if(Array.isArray(table_data) && table_data.length > 0){
            
             data = table_data.map((el,index)=>
                [index+1, el.type, el.posted_as, el.date, el.opening_date, el.closing_date, el.title, el.description,el.time_stamp, <Launch onClick={this.onActionClick.bind(this,el)} />]
            ) 
         
        }else{
            data= [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Notifications"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </div>
        )


    }
}
