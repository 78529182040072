import React, { Component } from 'react';
import {Switch, Link, Route} from 'react-router-dom'
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import AddNewContainer from '../Components/AddNew/AddNewContainer';
import EditNewContainer from '../Components/AddNew/EditNewContainer';
import Photos from '../Components/Photos/Photos';
class AddNewContainerMain extends Component {
    constructor(props) {
        super(props);

        let user = JSON.parse(localStorage.getItem('user'));
        
        
        this.state = {
            apikey: user.user.apikey,
            username: user.user.email,
            name: user.user.name,
            role: user.user.name
        }
        
    }
    
    render() {
        return (
            <div>
                <Navbar history={this.props.history} />
                <div className="main-body">
                    

                    <Switch>
                        <Route exact path="/notices" render={(props) => 
                            <AddNewContainer
                                {...props}
                                history={this.props.history}
                                title="Notification"
                                code="NOTICE"
                                apikey={this.state.apikey}
                                username={this.state.username}
                            />} />

                        <Route exact path="/tender" render={(props) =>
                            <AddNewContainer
                                {...props}
                                history={this.props.history}
                                title="Tender"
                                code="COLLEGE_TENDER"
                                apikey={this.state.apikey}
                                username={this.state.username}
                            />} />

                        <Route exact path="/news" render={(props) =>
                            <AddNewContainer
                                {...props}
                                history={this.props.history}
                                title="News"
                                code="NEWS"
                                apikey={this.state.apikey}
                                username={this.state.username}
                            />} />

                        <Route exact path="/events" render={(props) =>
                            <AddNewContainer
                                {...props}
                                history={this.props.history}
                                title="Events"
                                code="EVENTS"
                                apikey={this.state.apikey}
                                username={this.state.username}
                            />} />

                        <Route exact path="/edit" render={(props) =>
                            <EditNewContainer
                                {...props}
                                history={this.props.history}
                                title="Events"
                                code="EVENTS"
                                apikey={this.state.apikey}
                                username={this.state.username}
                            />} />


<Route exact path="/photos" render={(props) =>
                            <Photos 
                                type="COLLEGE" 
                                dept="BAHONA" 
                                history={this.props.history}
                                apikey={this.state.apikey}
                                username={this.state.username}
                            />} />

                            
                    </Switch>

                   

                </div>
            </div>
        )
    }
}

export default withAuth(AddNewContainerMain);