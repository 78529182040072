import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import history from "../../history";

class FacultySeminar extends Component {
  state = {
    m_type: this.props.m_type,
    employee_id: this.props.employee_id,
    email:this.props.email,
    message: "",
    open: false,
    isLoaded: false,
   date_year:'',
   organised_by:'',
   theme:'',
   title:'',
   sponsored_by:'',
   seminar:[]
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    //console.log(this.props);

    GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`)
    .then((resp)=>{
        if(resp){
            this.setState({
                ...resp,
          
                
              });
        }

        this.setState({
          isLoaded: true
        })
    })

    
  }

//   componentDidUpdate(prevProps){
//     if(this.props !== prevProps){
//         this.setState({
//             seminar:this.props.seminar
      
//         })
//     }
// }


  onSubmit = (e) => {
    e.preventDefault();

    let d = this.state;

    PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
  };


  onAddClick = () => {
    let d = {
        date_year: this.state.date_year,
        organised_by: this.state.organised_by,
        theme: this.state.theme,
        title: this.state.title,
        sponsored_by: this.state.sponsored_by,

    }

    let seminar = this.state.seminar;
    seminar.push(d);
    this.setState({
        seminar
    }, () => {
            this.setState({
                date_year:'',
                organised_by:'',
                theme:'',
                title:'',
                sponsored_by:'',
            })
    })
}

onDelete = (index) => {
    let seminar = this.state.seminar;
    seminar.splice(index,1);

    this.setState({
        seminar
    })
}

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

{this.state.isLoaded && <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            {this.props.title}
          </Typography>
          
           <table width="100%" className="table">
          <thead>
            <tr>
              <td>Date & Year</td>
              <td>Organized by</td>
              <td>Broad theme
of the seminar</td>
              <td>Title of the paper</td>

              <td>Seminar
sponsored by</td>
              
              <td>Click + to Add</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="date_year"
                  value={this.state.date_year}
                  onChange={this.onChange}
                  placeholder="Date & Year"
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="organised_by"
                  value={this.state.organised_by}
                  onChange={this.onChange}
                  placeholder="Organized by"
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="theme"
                  value={this.state.theme}
                  onChange={this.onChange}
                  placeholder="Broad theme
                  of the seminar"
                />
              </td>

              <td>
                <input
                  className="form-control"
                  type="text"
                  name="title"
                  value={this.state.title}
                  onChange={this.onChange}
                  placeholder="Title of the paper"
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="sponsored_by"
                  value={this.state.sponsored_by}
                  onChange={this.onChange}
                  placeholder="Seminar
                  sponsored by"
                />
              </td>
              
              <td>
                <Button
                  variant="outlined"
                  onClick={this.onAddClick}
                  type="button"
                >
                  +
                </Button>
              </td>
            </tr>
          </tbody>
          <tbody>
            {this.state.seminar.map((el, index) => (
              <tr key={index}>
                <td>{el.date_year}</td>
                <td>{el.organised_by}</td>
                <td>{el.theme}</td>
                <td>{el.title}</td>
                <td>{el.sponsored_by}</td>
                
                <td>
                  <Delete onClick={this.onDelete.bind(this, index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
                <br />
        <div align="right">
                <Button size="sm" variant="info" type="submit">
                  Submit
                </Button>
              </div>
        
        </form>}
      </div>
    );
  }
}

export default FacultySeminar;
