import { Paper, Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import DepartmentUsers from './DepartmentUsers/DepartmentUsers'


export default function UsersLanding({ apikey, username, role, history }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Typography gutterBottom variant="h6">
        Departmental Users
      </Typography>

      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label=""
        >
          <Tab label="Departmental Users" />
          <Tab label="Indivisual Users" />
        </Tabs>
      </Paper>

      <div style={{ paddingTop: 10 }}>
        
        {value == 0 && <DepartmentUsers apikey={apikey} />}
      </div>
    </div>
  )
}
