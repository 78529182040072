
import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { Button } from 'react-bootstrap';
import { PostData } from '../../api/service';
import Snack from '../Snackbar/Snack';
import FileUpload from '../AddNew/FileUpload';


class DepartmentHomePage extends Component {
    state = {
        banner: [],
        dept_code: this.props.dept_code,
        message: '',
        open: false,
        loadFile: true
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    componentDidMount() {
        if (this.props.banner != null) {
            //console.log(this.props.banner)
            this.setState({
                banner: this.props.banner,
                loadFile: false
            }, () => this.setState({ loadFile: true }))
        }

    }


    onSubmit = (e) => {
        e.preventDefault();

        let d = {
            value: this.state.banner,
            type: 'banner',
            dept_code: this.props.dept_code
        }

        PostData(`/admin/${this.props.dept_code}/uploaddata`, d)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    message: resp,
                    open: true
                })
            })
    }


    render() {
        return (
            <div>

                {this.state.open ? (
                    <Snack
                        open={this.state.open}
                        message={this.state.message}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                    />
                ) : null}

                <form onSubmit={this.onSubmit}>
                    <Typography
                        variant="h6"
                    >
                        Upload Departmental Banner
                    </Typography>

                    {this.state.loadFile ? (<FileUpload
                        dp={this.state.banner}
                        type={`DEPARTMENT_BANNER`}
                        setDp={this.handleChange.bind(this, 'banner')}
                    />) : null}

                    <br />

                    <div align="right">
                        <Button
                            size="sm"
                            variant="info"
                            type="submit"
                        >
                            Submit
                    </Button>
                    </div>

                </form>
            </div>
        );
    }
}


export default DepartmentHomePage;