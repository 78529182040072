import { Paper, Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import FileGroup from './FileGroupContainer/FileGroup'
import Group from './GroupContainer/Group'
import SubGroup from './SubGroupContainer/SubGroup'
import SubSubGroup from './SubSubGroupContainer/SubSubGroup'

export default function IqacLanding({ apikey, username, role, history }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Typography gutterBottom variant="h6">
        IQAC
      </Typography>

      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label=""
        >
          <Tab label="Group" />
          <Tab label="Sub-Group" />
          <Tab label="Sub-Sub-Group" />
          <Tab label="Files" />
        </Tabs>
      </Paper>

      <div style={{ paddingTop: 10 }}>
        {value == 0 && <Group apikey={apikey} />}
        {value == 1 && <SubGroup apikey={apikey} />}
        {value == 2 && <SubSubGroup apikey={apikey} />}
        {value == 3 && <FileGroup apikey={apikey} />}
      </div>
    </div>
  )
}
