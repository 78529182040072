import { Paper, Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import FileGroup from './FileGroupContainer/FileGroup'
import Group from './GroupContainer/Group'

export default function JournalLanding({ apikey, username, role, history }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Typography gutterBottom variant="h6">
        Anubandha Journal
      </Typography>

      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label=""
        >
          <Tab label="Issues" />
          <Tab label="Contents" />
        </Tabs>
      </Paper>

      <div style={{ paddingTop: 10 }}>
        {value == 0 && <Group apikey={apikey} />}
        {value == 1 && <FileGroup apikey={apikey} />}
      </div>
    </div>
  )
}
