import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { Tabs, Tab } from 'react-bootstrap';
import { GetData } from '../../api/service';
import CollegeEvent from './CollegeEvent';
import CollegeOthers from './CollegeOthers';
import CollegeFaculty from './CollegeFaculty';
import CollegeCommittee from './CollegeCommittee';
import ResourceLink from './ResourceLink';
import CollegeFeatured from './CollegeFeatured';


const smallTab = [
    {
        title: 'Notifications',
        active: 0
    },
    {
        title: 'Others',
        active: 1
    },
    {
        title: 'Staff',
        active: 2
    },
    {
        title: 'Committee',
        active: 3
    },
    {
        title: 'Resource Link',
        active: 4
    },
    {
        title: 'Featured Notification',
        active: 5
    },

]


class CollegeHomeLanding extends Component {
    state = {
        active: 0,
        dept_code: 'BAHONA',
        data: {},
        isDeptLoaded: false
    }

    __getDeptData = (dept_code) => {
        GetData(`/admin/${dept_code}/getsingledeptdata`)
            .then((resp) => {
                //console.log(resp)
                if (resp != false) {
                    this.setState({
                        data: resp,
                        active: 0,
                        isDeptLoaded: true
                    })
                } else {
                    this.setState({
                        data: {},
                        active: 0,
                        isDeptLoaded: true
                    })
                }

            })
    }

    componentDidMount() {

        this.__getDeptData(this.state.dept_code)

    }

    render() {
        return (
            <div>
                <Typography
                    variant="h6"
                >
                    Bahona College
                </Typography>
                <Typography
                    variant="subtitle2"
                >
                    Code: {this.state.dept_code}
                </Typography>


                <br />

                {this.state.isDeptLoaded ? (<Tabs activeKey={this.state.active} onSelect={k => this.setState({ active: k })}>

                    {smallTab.map((el, index) =>
                        <Tab key={index} eventKey={index} title={el.title}>
                            <br />

                            {this.state.active == 0 ? (<CollegeEvent
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}

                            {this.state.active == 1 ? (<CollegeOthers
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}

                            {this.state.active == 2 ? (<CollegeFaculty
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}

                            {this.state.active == 3 ? (<CollegeCommittee
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}

                            {this.state.active == 4 ? (<ResourceLink
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}


                            {this.state.active == 5 ? (<CollegeFeatured
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}



                        </Tab>

                    )}
                </Tabs>) : "Loading . . ."}
            </div>
        );
    }
}


export default CollegeHomeLanding;