import React, { Component } from 'react';
import { Typography, Grid, Table, TableHead, TableCell, TableRow, TableBody, Chip } from '@material-ui/core';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { Button, FormControl, Card } from 'react-bootstrap';
import { PostData, GetData, DeleteData, PutData } from '../../api/service';
import Snack from '../Snackbar/Snack';
import FileUpload from '../AddNew/FileUpload';
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import history from '../../history';

class FacultyBasic extends Component {
  state = {
    value: "",
    employee_id: this.props.employee_id,
    message: "",
    open: false,
    isLoaded: false,
    name: "",
    email: "",
    phone: "",
    type: "",
    appointment_type:'',
    about: "",
    date_of_joining: "",
    experience: "",
    dp: [],
    cv: [],
    data: [],
    is_hod: 0,
    loadFile: true,
    m_type:'',
    address:'',
    permanent_address:'',
    contact:''
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    //console.log(this.props);

    this.setState({
      ...this.props,
      
      isLoaded: true,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();

    let d = this.state;

    PostData(`/admin/${this.props.employee_id}/uploademployeedatanew`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
  };

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

       {this.state.isLoaded && <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            {this.props.title}
          </Typography>

          <Card>
            <Card.Body>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Type</label>
                                    <FormControl
                                        as="select"
                                        name="type"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.type}
                                    >
                                        <option value="">Select Type</option>
                                        <option value="PRINCIPAL">PRINCIPAL</option>
                                        <option value="VICE PRINCIPAL">VICE PRINCIPAL</option>
                                        <option value="FACULTY">FACULTY</option>
                                        <option value="OFFICE">OFFICE ASSISTANT</option>
                                        <option value="LIB">LIBRARY STAFF</option>
                                        <option value="BEARER">BEARER</option>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Nature of Appointment</label>
                                    <FormControl
                                        as="select"
                                        name="appointment_type"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.appointment_type}
                                    >
                                        <option value="">Select Nature of Appointment</option>
                                        <option value="REGULAR">REGULAR</option>
                                        <option value="TEMPORARY">TEMPORARY</option>
                                        <option value="NON SANCTIONED">NON SANCTIONED</option>
                                    </FormControl>
                                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Name</label>
                  <FormControl
                    as="input"
                    name="name"
                    required
                    onChange={this.onChange}
                    value={this.state.name}
                    placeholder="Name"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <label>Designation</label>
                  <FormControl
                    as="input"
                    name="designation"
                    onChange={this.onChange}
                    value={this.state.designation}
                    placeholder="Designation"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Email</label>
                  <FormControl
                    as="input"
                    name="email"
                    onChange={this.onChange}
                    value={this.state.email}
                    placeholder="Email"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Phone</label>
                  <FormControl
                    as="input"
                    name="phone"
                    onChange={this.onChange}
                    value={this.state.phone}
                    placeholder="phone"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Date of Joining</label>
                  <FormControl
                    as="input"
                    name="date_of_joining"
                    onChange={this.onChange}
                    value={this.state.date_of_joining}
                    placeholder="Date of Joining"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Highest Qualification</label>
                  <FormControl
                    as="input"
                    name="h_qualification"
                    
                    onChange={this.onChange}
                    value={this.state.h_qualification}
                    placeholder="Highest Qualification"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>Experience</label>
                  <FormControl
                    as="input"
                    name="experience"
                    onChange={this.onChange}
                    value={this.state.experience}
                    placeholder="Experience"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label>is HOD ?</label>
                  <FormControl
                    as="select"
                    name="is_hod"
                    onChange={this.onChange}
                    value={this.state.is_hod}
                  >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>Present Address</label>
                  <ReactQuill
                    placeholder="Present Address"
                    value={this.state.address}
                    onChange={this.handleChange.bind(this, "address")}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>Peremanent Address</label>
                  <ReactQuill
                    placeholder="Peremanent Address"
                    value={this.state.permanent_address}
                    onChange={this.handleChange.bind(this, "permanent_address")}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>Contact Details</label>
                  <ReactQuill
                    placeholder="Contact Details"
                    value={this.state.contact}
                    onChange={this.handleChange.bind(this, "contact")}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>About the employee</label>
                  <ReactQuill
                    placeholder="Type something here"
                    value={this.state.about}
                    onChange={this.handleChange.bind(this, "about")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <label>Upload Display Picture</label>
                  {this.state.loadFile ? (
                    <FileUpload
                      dp={this.state.dp}
                      type={`EMPLOYEE_DP`}
                      setDp={this.handleChange.bind(this, "dp")}
                    />
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <label>Upload CV</label>
                  {this.state.loadFile ? (
                    <FileUpload
                      dp={this.state.cv}
                      type={`EMPLOYEE_CV`}
                      setDp={this.handleChange.bind(this, "cv")}
                    />
                  ) : null}
                </Grid>
              </Grid>

              <div align="right">
                <Button size="sm" variant="info" type="submit">
                  Submit
                </Button>
              </div>
            </Card.Body>
          </Card>
        </form>}
      </div>
    );
  }
}

export default FacultyBasic;
