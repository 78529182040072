import React, { Component } from 'react'
import Sel from 'react-select'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import { PostData } from '../../../api/service';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px'
    }
})

class CourseAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            session: '',
            course: '',
            stream: '',
            semester: '',
            sub_code: '',
            section: '',
            id: '',
            term_name: 'TERM',
            term_list: [1, 2, 3, 4, 5, 6],
            student_data: [],
            f_students: [],
            isLoadingStudent: true
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    __getStudents(course,stream,semester,sub_code){
        let data = {
            course,stream,semester,sub_code
        }

        PostData(`/${this.props.apikey}/${this.props.username}/getstudentsforsectionentry`, data)
        .then((resp) => {
            //console.log(resp);
            if(Array.isArray(resp) && resp.length > 0){
                let f_students = this.state.f_students;

                f_students = resp.map((el,index) =>{
                    return({
                        label: el.roll_no + ' ' + el.name,
                        value: el.email,
                        roll_no: el.roll_no,
                        name: el.name,
                        email: el.email
                    })
                })

                this.setState({
                    f_students,
                    isLoadingStudent: false
                })

            }
        })
    }

    handleSChange = (name, data) => {
        this.setState({
            [name]: data
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'course') {
            let term_name = ""; let term_list = [];
            let course = this.props.courses;
            let results = course.filter((el) => el.name === e.target.value);
            if (results.length > 0) {
                term_name = results[0].tenure;
                let no_of_term = parseFloat(results[0].no_of_term);
                for (let i = 1; i <= no_of_term; i++) {
                    term_list.push(i);
                }
                this.setState({
                    term_name,
                    term_list
                })
            }


            //fetch student
        }

        if(e.target.name === 'semester'){
            let semester = e.target.value;
            if(this.state.course !== '' && this.state.stream !== '' && semester !== '' && this.state.sub_code !== ''){
                this.__getStudents(this.state.course,this.state.stream,semester,this.state.sub_code);
            }
        }

        if (e.target.name === 'sub_code') {
            let sub_code = e.target.value;
            if (this.state.course !== '' && this.state.stream !== '' && this.state.semester !== '' && sub_code !== '') {
                this.__getStudents(this.state.course, this.state.stream, this.state.semester, sub_code);
            }
        }
    }

    componentDidMount() {
        if (this.props.edit) {

            //load subjects

            this.__getStudents(this.props.edit_data.course, this.props.edit_data.stream, this.props.edit_data.semester, this.props.edit_data.sub_code)

            this.setState({
                ...this.props.edit_data
            })
        } else {
            this.setState({
                session: '',
                course: '',
                stream: '',
                semester: '',
                sub_code: '',
                section: '',
                id: '',
                term_name: 'TERM',
                term_list: [1, 2, 3, 4, 5, 6],
                student_data: [],
                f_students: [],
                isLoadingStudent: true
            })
        }




    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                this.__getStudents(this.props.edit_data.course, this.props.edit_data.stream, this.props.edit_data.semester, this.props.edit_data.sub_code)
                this.setState({
                    ...this.props.edit_data
                })
            } else {
                this.setState({
                    session: '',
                    course: '',
                    stream: '',
                    semester: '',
                    section: '',
                    sub_code: '',
                    id: '',
                    term_name: 'TERM',
                    term_list: [1, 2, 3, 4, 5, 6],
                    student_data: [],
                    f_students: [],
                    isLoadingStudent: true
                })
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;
       

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            
        }
        
        this.setState({
            session: '',
            course: '',
            stream: '',
            semester: '',
            sub_code: '',
            section: '',
            id: '',
            term_name: 'TERM',
            term_list: [1, 2, 3, 4, 5, 6],
            student_data: [],
            f_students: [],
            isLoadingStudent: true
        })

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    render() {
        let { classes } = this.props;
        let stream = [];
        let course = [];
        let session = [];
        let subject = [];
        let semester = [];
        if (this.props.is_all_loaded) {
            let i = this.props.courses;
            if (Array.isArray(i) && i.length > 0) {
                course = i.map((el, index) =>
                    <MenuItem key={index} value={el.name}>{el.name}</MenuItem>
                )
            }

            let j = this.props.streams;
            if (Array.isArray(j) && j.length > 0) {
                stream = j.map((el, index) =>
                    <MenuItem key={index} value={el.stream_name}>{el.stream_name}</MenuItem>
                )
            }

            let k = this.state.term_list;
            if (Array.isArray(k) && k.length > 0) {
                semester = k.map((el, index) =>
                    <MenuItem key={index} value={el}>{el}</MenuItem>
                )
            }

            let l = this.props.sessions;
            if (Array.isArray(l) && l.length > 0) {
                session = l.map((el, index) =>
                    <MenuItem key={index} value={el.session_name}>{el.session_name}</MenuItem>
                )
            }

            let m = this.props.subjects;
            if (Array.isArray(m) && m.length > 0) {
                subject = m.map((el, index) =>
                    <MenuItem key={index} value={el.sub_code}>{el.sub_name}</MenuItem>
                )
            }
        }
        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Session</InputLabel>
                                <Select
                                    value={this.state.session}
                                    onChange={this.onChange}
                                    name="session"
                                    inputProps={{
                                        name: 'session'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {session}
                                </Select>
                            </FormControl>

                            <br />
                            <br />


                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Course</InputLabel>
                                <Select
                                    value={this.state.course}
                                    onChange={this.onChange}
                                    name="course"
                                    inputProps={{
                                        name: 'course'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {course}
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Stream</InputLabel>
                                <Select
                                    value={this.state.stream}
                                    onChange={this.onChange}
                                    name="stream"
                                    inputProps={{
                                        name: 'stream'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {stream}
                                </Select>
                            </FormControl>

                            <br />
                            <br />


                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select {this.state.term_name}</InputLabel>
                                <Select
                                    value={this.state.semester}
                                    onChange={this.onChange}
                                    name="semester"
                                    inputProps={{
                                        name: 'semester'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {semester}
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Subject Code</InputLabel>
                                <Select
                                    value={this.state.sub_code}
                                    onChange={this.onChange}
                                    name="sub_code"
                                    inputProps={{
                                        name: 'sub_code'
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {subject}
                                </Select>
                            </FormControl>


                            <br />
                            <br />

                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Section</InputLabel>
                                <Select
                                    value={this.state.section}
                                    onChange={this.onChange}
                                    name="section"
                                    inputProps={{
                                        name: 'section',
                                    
                                    }}
                                    
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="A">A</MenuItem>
                                    <MenuItem value="B">B</MenuItem>
                                    <MenuItem value="C">C</MenuItem>
                                    <MenuItem value="D">D</MenuItem>
                                </Select>
                            </FormControl>

                            <br/>
                            <br />
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink className={classes.textStyle}>Select Students</InputLabel>
                                <Sel
                                    isMulti
                                    value={this.state.student_data}
                                    onChange={this.handleSChange.bind(this, "student_data")}
                                    options={this.state.f_students}
                                    placeholder="Select Students"
                                />
                            </FormControl>
                            <div>

                                <br />
                                <br />

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(CourseAdd)