import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './auth_components/js/Login';
import HomePage from './Containers/HomePage';

import InitialisationContainer from './Containers/InitialisationContainer';

import MasterContainer from './Containers/MasterContainer';

import SettingsContainer from './Containers/SettingsContainer';

import LogContainer from './Containers/LogContainer';
import AddNewContainerMain from './Containers/AddNewContainerMain';
import ViewAllContainer from './Containers/ViewAllContainer';
import DepartmentContainer from './Containers/DepartmentContainer';
import CollegeContainer from './Containers/CollegeContainer';
import FacultyContainer from './Containers/FacultyContainer';
import IqacContainer from './Containers/IqacContainer';
import UsersContainer from './Containers/UsersContainer';
import JournalContainer from './Containers/JournalContainer';



class App extends Component {

  

  // get the role

  constructor(props) {
    super(props);
    let user1 = JSON.parse(localStorage.getItem('user'));
        if(user1 != null){
            let user = user1.user;
            this.state = {
                apikey: user.apikey,
                username: user.email,
                role: user.role,
                employee_id: user.employee_id
            }
        }else{
          this.state = {
            apikey: '',
            username: '',
            role: '',
            employee_id: ''
        }
        }
}

componentDidMount(){
  if(this.state.role != ''){
    if(this.state.role == 'SUPERADMIN'){

    }else if(this.state.role == 'FACULTY'){
      history.push(`/faculty/${this.state.employee_id}/home`)
    }else{
      history.push(`/departments/${this.state.role}/home`)
    }
  }else{
    history.push(`/login`)
  }
  
}



  render() {
    return (
      <Router history={history} >
      
        <div>
          <Switch>

          <Route path="/login" component={Login} />

            {this.state.role === 'SUPERADMIN' && <>
            
            <Route exact path="/" component={HomePage} />

            <Route exact path="/initialisation" component={InitialisationContainer} />     
            <Route exact path="/settings" component={SettingsContainer} />     
            <Route exact path="/photos" component={AddNewContainerMain} /> 

            <Route exact path="/college" component={CollegeContainer} />     
            <Route exact path="/departments" component={DepartmentContainer} />     
            <Route exact path="/departments/:dept_code/home" component={DepartmentContainer} />     
            <Route exact path="/faculty/:employee_id/home" component={FacultyContainer} />     



            <Route exact path="/notices" component={AddNewContainerMain} />     
            <Route exact path="/tender" component={AddNewContainerMain} />     
            <Route exact path="/news" component={AddNewContainerMain} />     
            <Route exact path="/events" component={AddNewContainerMain} />     
            <Route exact path="/edit" component={AddNewContainerMain} />     
            <Route exact path="/view" component={ViewAllContainer} />     

            <Route exact path="/master/course" component={MasterContainer} />       
            <Route exact path="/master/department" component={MasterContainer} />       
            <Route exact path="/master/subject" component={MasterContainer} />       
            <Route exact path="/master/stream" component={MasterContainer} />       
            <Route exact path="/master/session" component={MasterContainer} />     
            <Route exact path="/master/leave" component={MasterContainer} />     
            <Route exact path="/master/class" component={MasterContainer} />     
            <Route exact path="/master/routine" component={MasterContainer} />     
            <Route exact path="/master/section" component={MasterContainer} />     
            <Route exact path="/master/access" component={MasterContainer} />     
            <Route exact path="/master/doctype" component={MasterContainer} />     
            <Route exact path="/master/passwordchange" component={MasterContainer} />     
            
            
      





            <Route exact path="/logs" component={LogContainer} /> 
            <Route exact path="/iqac" component={IqacContainer} /> 
            <Route exact path="/journal" component={JournalContainer} /> 
            <Route exact path="/users" component={UsersContainer} /> 
            </>}



            {this.state.role != 'SUPERADMIN' && this.state.role != 'FACULTY' && <>
            <Route path="/login" component={Login} />
            <Route exact path="/departments/:dept_code/home" component={DepartmentContainer} />     
            </>}

            {this.state.role == 'FACULTY' && <>
            <Route path="/login" component={Login} />
            <Route exact path="/faculty/:employee_id/home" component={FacultyContainer} />   
            </>}

            
            



          </Switch>
        </div>
       
      </Router>
    );
  }
}

export default App;
