import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import history from "../../history";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

class FacultyPublications extends Component {
  state = {
    m_type: this.props.m_type,
    employee_id: this.props.employee_id,
    email: this.props.email,
    message: "",
    open: false,
    isLoaded: false,
    date_year: "",
    title_paper: "",
    title_book: "",
    published_by: "",
    isbn_no: "",
    issn_no:'',
    other_info:'',
    research_paper: [],
    reserarch_paper_panel:false,
    journal: [],
    journal_panel:false,
    text_book: [],
    text_book_panel:false,
    magazine: [],
    magazine_panel:false,
    popular_book: [],
    popular_book_panel:false,
    news_paper:[],
    news_paper_panel:false,
    other_publication:[],
    other_publication_panel:false,
    econtent:[],
    econtent_panel:false
  };

  clearData = ()=>{
      this.setState({
        date_year: "",
        title_paper: "",
        title_book: "",
        published_by: "",
        isbn_no: "",
        issn_no:'',
        other_info:'', 
      })
  }

  onPanelClick = (type,flag)=>{
        this.clearData();
        this.setState({
        reserarch_paper_panel:type=='reserarch_paper_panel'?flag:false,
        journal_panel:type=='journal_panel'?flag:false,
        text_book_panel:type=='text_book_panel'?flag:false,
        magazine_panel:type=='magazine_panel'?flag:false,
        popular_book_panel:type=='popular_book_panel'?flag:false,
        news_paper_panel:type=='news_paper_panel'?flag:false,
        other_publication_panel:type=='other_publication_panel'?flag:false,
        econtent_panel:type=='econtent_panel'?flag:false

        })
    
  }

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    //console.log(this.props);

    GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`).then(
      (resp) => {
        if (resp) {
          this.setState({
            ...resp,

            
          });
        }
        this.setState({
          isLoaded: true
        })
      }
    );
  }

  //   componentDidUpdate(prevProps){
  //     if(this.props !== prevProps){
  //         this.setState({
  //             seminar:this.props.seminar

  //         })
  //     }
  // }

  onSubmit = (e) => {
    e.preventDefault();

    let d = this.state;

    PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
  };

  onAddClick = (type) => {
    if (type == "research_paper") {
      let d = {
        date_year: this.state.date_year,
        title_paper: this.state.title_paper,
        title_book: this.state.title_book,
        published_by: this.state.published_by,
        isbn_no: this.state.isbn_no,
      };

      let research_paper = this.state.research_paper;
      research_paper.push(d);
      this.setState(
        {
          research_paper,
        },
        () => {
          this.setState({
            date_year: "",
            title_paper: "",
            title_book: "",
            published_by: "",
            isbn_no: "",
          });
        }
      );
    }

    else if (type == "journal") {
        let d = {
          date_year: this.state.date_year,
          title_paper: this.state.title_paper,
          title_book: this.state.title_book,
          published_by: this.state.published_by,
          issn_no: this.state.issn_no,
        };
  
        let journal = this.state.journal;
        journal.push(d);
        this.setState(
          {
            journal,
          },
          () => {
            this.setState({
              date_year: "",
              title_paper: "",
              title_book: "",
              published_by: "",
              issn_no: "",
            });
          }
        );
      }

      else  if (type == "text_book") {
        let d = {
          date_year: this.state.date_year,
          title_book: this.state.title_book,
          published_by: this.state.published_by,
          isbn_no: this.state.isbn_no,
        };
  
        let text_book = this.state.text_book;
        text_book.push(d);
        this.setState(
          {
            text_book,
          },
          () => {
            this.setState({
              date_year: "",
              title_book: "",
              published_by: "",
              isbn_no: "",
            });
          }
        );
      }
      else  if (type == "magazine") {
        let d = {
          date_year: this.state.date_year,
          title_book: this.state.title_book,
          published_by: this.state.published_by,
          isbn_no: this.state.isbn_no,
          other_info: this.state.other_info,
        };
  
        let magazine = this.state.magazine;
        magazine.push(d);
        this.setState(
          {
            magazine,
          },
          () => {
            this.setState({
              date_year: "",
              title_book: "",
              published_by: "",
              isbn_no: "",
              other_info:''
            });
          }
        );
      }

      else if (type == "popular_book") {
        let d = {
          date_year: this.state.date_year,
          title_paper: this.state.title_paper,
          title_book: this.state.title_book,
          published_by: this.state.published_by,
          isbn_no: this.state.isbn_no,
        };
  
        let popular_book = this.state.popular_book;
        popular_book.push(d);
        this.setState(
          {
            popular_book,
          },
          () => {
            this.setState({
              date_year: "",
              title_paper: "",
              title_book: "",
              published_by: "",
              isbn_no: "",
            });
          }
        );
      }

      else if (type == "news_paper") {
        let d = {
          date_year: this.state.date_year,
          title_paper: this.state.title_paper,
          title_book: this.state.title_book,
        };
  
        let news_paper = this.state.news_paper;
        news_paper.push(d);
        this.setState(
          {
            news_paper,
          },
          () => {
            this.setState({
              date_year: "",
              title_paper: "",
              title_book: "",
            });
          }
        );
      }

      else if (type == "other_publication") {
        let d = {
          date_year: this.state.date_year,
          title_book: this.state.title_book,
          published_by: this.state.published_by,
          isbn_no: this.state.isbn_no,
          other_info: this.state.other_info,
        };
  
        let other_publication = this.state.other_publication;
        other_publication.push(d);
        this.setState(
          {
            other_publication,
          },
          () => {
            this.setState({
              date_year: "",
              title_book: "",
              published_by: "",
              isbn_no: "",
              other_info:''
            });
          }
        );
      }

      else if (type == "econtent") {
        let d = {
          date_year: this.state.date_year,
          title_paper: this.state.title_paper,
          title_book: this.state.title_book,
          published_by: this.state.published_by,
          other_info: this.state.other_info,
        };
  
        let econtent = this.state.econtent;
        econtent.push(d);
        this.setState(
          {
            econtent,
          },
          () => {
            this.setState({
              date_year: "",
              title_paper:'',
              title_book: "",
              published_by: "",
             
              other_info:''
            });
          }
        );
      }
  };

  onDelete = (index, type) => {
    if (type == "research_paper") {
      let research_paper = this.state.research_paper;
      research_paper.splice(index, 1);

      this.setState({
        research_paper,
      });
    }

    else if (type == "journal") {
        let journal = this.state.journal;
        journal.splice(index, 1);
  
        this.setState({
            journal,
        });
      }

      else if (type == "text_book") {
        let text_book = this.state.text_book;
        text_book.splice(index, 1);
  
        this.setState({
            text_book,
        });
      }

      else if (type == "magazine") {
        let magazine = this.state.magazine;
        magazine.splice(index, 1);
  
        this.setState({
            magazine,
        });
      }

      else if (type == "popular_book") {
        let popular_book = this.state.popular_book;
        popular_book.splice(index, 1);
  
        this.setState({
            popular_book,
        });
      }

      else if (type == "news_paper") {
        let news_paper = this.state.news_paper;
        news_paper.splice(index, 1);
  
        this.setState({
            news_paper,
        });
      }

      else if (type == "other_publication") {
        let other_publication = this.state.other_publication;
        other_publication.splice(index, 1);
  
        this.setState({
            other_publication,
        });
      }

      else if (type == "econtent") {
        let econtent = this.state.econtent;
        econtent.splice(index, 1);
  
        this.setState({
            econtent,
        });
      }
  };

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

        {this.state.isLoaded && (
          <form onSubmit={this.onSubmit}>
            <Typography variant="h6" gutterBottom>
              {this.props.title}
            </Typography>

            <ExpansionPanel expanded={this.state.reserarch_paper_panel} onChange={() => this.onPanelClick('reserarch_paper_panel',!this.state.reserarch_paper_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Seminar/Research Paper in Book</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Seminar/Research Paper in Book</Typography>
                  </td>
                </tr>
                <tr>
                  <td>Date / Year</td>

                  <td>Title of the paper</td>

                  <td>Title of the Book</td>
                  <td>Published By</td>
                  <td>ISBN</td>

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Date / Year"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={this.state.title_paper}
                      onChange={this.onChange}
                      placeholder="Title of the Paper"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the Book"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={this.state.isbn_no}
                      onChange={this.onChange}
                      placeholder="ISBN"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "research_paper")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {this.state.research_paper.map((el, index) => (
                  <tr key={index}>
                    <td>{index+1}. {el.date_year}</td>
                    <td>{el.title_paper}</td>
                    <td>{el.title_book}</td>
                    <td>{el.published_by}</td>
                    <td>{el.isbn_no}</td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "research_paper"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />

            <ExpansionPanel expanded={this.state.journal_panel} onChange={() => this.onPanelClick('journal_panel',!this.state.journal_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Seminar/Research Paper in Journals</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Seminar/Research Paper in Journals</Typography>
                  </td>
                </tr>
                <tr>
                  <td>Date / Year</td>

                  <td>Title of the paper</td>

                  <td>Title of the Journal</td>
                  <td>Published By</td>
                  <td>ISSN</td>

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Date / Year"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={this.state.title_paper}
                      onChange={this.onChange}
                      placeholder="Title of the Paper"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the Journal"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="issn_no"
                      value={this.state.issn_no}
                      onChange={this.onChange}
                      placeholder="ISSN"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "journal")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {this.state.journal.map((el, index) => (
                  <tr key={index}>
                    <td>{index+1}. {el.date_year}</td>
                    <td>{el.title_paper}</td>
                    <td>{el.title_book}</td>
                    <td>{el.published_by}</td>
                    <td>{el.issn_no}</td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "journal"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel>
            

            <br />
            <ExpansionPanel expanded={this.state.text_book_panel} onChange={() => this.onPanelClick('text_book_panel',!this.state.text_book_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Text Book</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={5} align="center">
                    <Typography>Text Book</Typography>
                  </td>
                </tr>
                <tr>
                  <td>Year</td>

                 

                  <td>Title of the Book</td>
                  <td>Published By</td>
                  <td>ISBN</td>

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Year"
                    />
                  </td>
                 

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the Book"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={this.state.isbn_no}
                      onChange={this.onChange}
                      placeholder="ISBN"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "text_book")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {this.state.text_book.map((el, index) => (
                  <tr key={index}>
                    <td>{index+1}. {el.date_year}</td>
                  
                    <td>{el.title_book}</td>
                    <td>{el.published_by}</td>
                    <td>{el.isbn_no}</td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "text_book"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel>


            <br />
            <ExpansionPanel expanded={this.state.magazine_panel} onChange={() => this.onPanelClick('magazine_panel',!this.state.magazine_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Book/Journal/ Magazine Edited</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Book/Journal/ Magazine Edited</Typography>
                  </td>
                </tr>
                <tr>
                  <td>Year</td>

                 

                  <td>Title of the
Book/journal/Magazine</td>
                  <td>Published By</td>
                  <td>ISBN / ISSN</td>
                            <td>Any other
information</td>
                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Year"
                    />
                  </td>
                 

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the
                      Book/journal/Magazine"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={this.state.isbn_no}
                      onChange={this.onChange}
                      placeholder="ISBN / ISSN"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={this.state.other_info}
                      onChange={this.onChange}
                      placeholder="Any other
                      information"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "magazine")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {this.state.magazine.map((el, index) => (
                  <tr key={index}>
                    <td>{index+1}. {el.date_year}</td>
                  
                    <td>{el.title_book}</td>
                    <td>{el.published_by}</td>
                    <td>{el.isbn_no}</td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "magazine"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel expanded={this.state.popular_book_panel} onChange={() => this.onPanelClick('popular_book_panel',!this.state.popular_book_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Article in Popular Book/General Book</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Article in Popular Book/General Book</Typography>
                  </td>
                </tr>
                <tr>
                  <td>Date / Year</td>

                  <td>Title of the paper</td>

                  <td>Title of the Book</td>
                  <td>Published By</td>
                  <td>ISBN</td>

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Date / Year"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={this.state.title_paper}
                      onChange={this.onChange}
                      placeholder="Title of the Paper"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the Book"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={this.state.isbn_no}
                      onChange={this.onChange}
                      placeholder="ISBN No"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "popular_book")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {this.state.popular_book.map((el, index) => (
                  <tr key={index}>
                    <td>{index+1}. {el.date_year}</td>
                    <td>{el.title_paper}</td>
                    <td>{el.title_book}</td>
                    <td>{el.published_by}</td>
                    <td>{el.isbn_no}</td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "popular_book"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <br />
            <ExpansionPanel expanded={this.state.news_paper_panel} onChange={() => this.onPanelClick('news_paper_panel',!this.state.news_paper_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Publication in News Paper</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={4} align="center">
                    <Typography>Publication in News Paper</Typography>
                  </td>
                </tr>
                <tr>
                  <td>Date / Year</td>

                  <td>Title of the Article</td>

                  <td>Name of the News Paper</td>
                 

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Date / Year"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={this.state.title_paper}
                      onChange={this.onChange}
                      placeholder="Title of the Article"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Name of the News Paper"
                    />
                  </td>
                 

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "news_paper")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {this.state.news_paper.map((el, index) => (
                  <tr key={index}>
                    <td>{index+1}. {el.date_year}</td>
                    <td>{el.title_paper}</td>
                    <td>{el.title_book}</td>
                    

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "news_paper"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
</ExpansionPanel>
            <br />

            <ExpansionPanel expanded={this.state.other_publication_panel} onChange={() => this.onPanelClick('other_publication_panel',!this.state.other_publication_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Other Publication with details</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Other Publication with details</Typography>
                  </td>
                </tr>
                <tr>
                  <td>Year</td>

                 

                  <td>Title of the
                  Publication</td>
                  <td>Published By</td>
                  <td>ISBN / ISSN</td>
                            <td>Any other
information</td>
                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Year"
                    />
                  </td>
                 

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the
                      Publication"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={this.state.isbn_no}
                      onChange={this.onChange}
                      placeholder="ISBN / ISSN"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={this.state.other_info}
                      onChange={this.onChange}
                      placeholder="Any other
                      information"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "other_publication")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {this.state.other_publication.map((el, index) => (
                  <tr key={index}>
                    <td>{index+1}. {el.date_year}</td>
                  
                    <td>{el.title_book}</td>
                    <td>{el.published_by}</td>
                    <td>{el.isbn_no}</td>
                    <td>{el.other_info}</td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "other_publication"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <br />
            <ExpansionPanel expanded={this.state.econtent_panel} onChange={() => this.onPanelClick('econtent_panel',!this.state.econtent_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add E-CONTENT DEVELOPMENT</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>E-CONTENT DEVELOPMENT</Typography>
                  </td>
                </tr>
                <tr>
                  <td>Year</td>

                 

                  <td>Type of
E-content</td>
<td>Title of the Publication</td>
                  <td>Published By</td>
                  
                            <td>Remarks</td>
                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Year"
                    />
                  </td>
                 

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={this.state.title_paper}
                      onChange={this.onChange}
                      placeholder="Type of
                      E-content"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the Publication"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={this.state.other_info}
                      onChange={this.onChange}
                      placeholder="Remarks"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "econtent")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {this.state.econtent.map((el, index) => (
                  <tr key={index}>
                    <td>{index+1}. {el.date_year}</td>
                  
                    <td>{el.title_paper}</td>
                    <td>{el.title_book}</td>
                    <td>{el.published_by}</td>
                    <td>{el.other_info}</td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "econtent"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />

            <div align="right">
              <Button size="sm" variant="info" type="submit">
                Submit
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default FacultyPublications;
