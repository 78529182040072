import React, { Component } from 'react'
import { withStyles, Typography, TextField, Grid, Card, CardHeader, CardContent, CardActions, Button } from '@material-ui/core';
import { PostData } from '../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import AuthService from './../../auth_components/js/AuthService';

const styles = theme => ({
    textStyle: {
        fontWeight: 400
    },
    messageField: {
        width: '100%'
    }
})

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            old_password: '',
            new_password: '',
            confirm_password: '',
            username: this.props.username,
            error: {}
        }
        this.Auth = new AuthService();
    }


    onLogout() {
        this.Auth.logout();
        this.props.history.replace('/login');
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleValidation() {
        let { confirm_password, new_password } = this.state;
        let error = this.state.error;
        let isValid = false;

        if (confirm_password === new_password) {
            isValid = true;
            error['confirm_password'] = "";
        } else {
            isValid = false;
            error['confirm_password'] = "Passwords Do not match"
        }

        this.setState({
            error
        })

        return isValid;
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            const data = {
                old_password: this.state.old_password,
                new_password: this.state.new_password,
                email: this.props.username
            }
            PostData(`/collegeuser/change_password`, data)
                .then((resp) => {
                    if (resp === true) {
                        confirmAlert({
                            title: 'Successfully Changed',
                            message: 'You will be redirected to login page',
                            buttons: [
                                {
                                    label: 'OKAY',
                                    onClick: () => this.onLogout()
                                }
                            ]
                        })
                    } else {
                        let error = this.state.error;
                        error['old_password'] = "Old Password not matched";
                        this.setState({
                            error
                        })
                    }
                })
        }
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <Card>
                        <CardHeader
                            title="Change Password"
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4} md={4}>
                                    <TextField
                                        label="Old Password"
                                        name="old_password"
                                        className={classes.messageField}
                                        type="password"
                                        margin="normal"
                                        required
                                        InputProps={{
                                            required: true
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            required: true,

                                        }}
                                        value={this.state.old_password}
                                        onChange={this.onChange}
                                    />

                                    <span style={{ color: 'red' }}>{this.state.error['old_password']}</span>
                                </Grid>

                                <Grid item xs={12} lg={4} md={4}>

                                    <TextField
                                        label="New Password"
                                        name="new_password"
                                        className={classes.messageField}
                                        type="password"
                                        margin="normal"
                                        required
                                        InputProps={{
                                            required: true
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            required: true,

                                        }}
                                        value={this.state.new_password}
                                        onChange={this.onChange}
                                    />
                                    <span style={{ color: 'red' }}>{this.state.error['new_password']}</span>
                                </Grid>

                                <Grid item xs={12} lg={4} md={4}>

                                    <TextField
                                        label="Confirm Password"
                                        name="confirm_password"
                                        className={classes.messageField}
                                        type="password"
                                        margin="normal"
                                        required
                                        InputProps={{
                                            required: true
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            },
                                            required: true,

                                        }}
                                        value={this.state.confirm_password}
                                        onChange={this.onChange}
                                    />

                                    <span style={{ color: 'red' }}>{this.state.error['confirm_password']}</span>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <div style={{ textAlign: 'right', width: '100%' }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit">Submit</Button>
                            </div>

                        </CardActions>
                    </Card>
                </form>

            </div>
        )
    }
}

export default withStyles(styles)(ChangePassword);