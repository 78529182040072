import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { Button } from 'react-bootstrap';
import { PostData } from '../../api/service';
import Snack from '../Snackbar/Snack';


class DepartmentCourses extends Component {
    state = {
        courses: '',
        dept_code: this.props.dept_code,
        message: '',
        open: false
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    componentDidMount() {
        if (this.props.courses != null) {
            this.setState({
                courses: this.props.courses
            })
        }

    }


    onSubmit = (e) => {
        e.preventDefault();

        let d = {
            value: this.state.courses,
            type: 'courses',
            dept_code: this.props.dept_code
        }

        PostData(`/admin/${this.props.dept_code}/uploaddata`, d)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    message: resp,
                    open: true
                })
            })
    }


    render() {
        return (
            <div>

                {this.state.open ? (
                    <Snack
                        open={this.state.open}
                        message={this.state.message}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                    />
                ) : null}

                <form onSubmit={this.onSubmit}>
                    <Typography
                        variant="h6"
                    >
                        Courses
                    </Typography>

                    <ReactQuill
                        placeholder="Type something here"
                        value={this.state.courses}
                        onChange={this.handleChange.bind(this, 'courses')}
                    />

                    <br />

                    <div align="right">
                        <Button
                            size="sm"
                            variant="info"
                            type="submit"
                        >
                            Submit
                    </Button>
                    </div>

                </form>
            </div>
        );
    }
}


export default DepartmentCourses;