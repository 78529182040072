import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import ViewAllNotices from '../Components/ViewAll/ViewAllNotices';

class ViewAllContainer extends Component {
    constructor(props) {
        super(props);

        let user = JSON.parse(localStorage.getItem('user'));
        
        
        this.state = {
            apikey: user.user.apikey,
            username: user.user.email,
            name: user.user.name,
            role: user.user.name
        }
        
    }
    
    render() {
        return (
            <div>
                <Navbar history={this.props.history} />
                <div className="main-body">

                    
                    <ViewAllNotices
                        apikey={this.state.apikey}
                        username={this.state.username}
                        history={this.props.history}

                    />
                   

                </div>
            </div>
        )
    }
}

export default withAuth(ViewAllContainer);